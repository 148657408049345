<template>
    <div class="modal fade show add-note-modal" aria-labelledby="addNewCardTitle">
        <div class="modal-dialog modal-dialog-centered modal-lg">
            <div class="modal-content">
                <div class="row">
                    <div class="col-12">
                        <div class="d-flex justify-content-between px-1 my-1 align-item-center">
                            <h1 class="mb-0">{{ editData ? 'Edit' : 'Add' }} Baby Notes</h1>
                            <button @click="closeModal" type="button" class="btn-close" data-bs-dismiss="modal"
                                aria-label="Close"></button>
                        </div>
                    </div>
                </div>
                <hr class="mt-0">

                <div class="modal-body">

                    <div class="pb-2">
                        
                        <div class="row">
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label" for="gender">Gender</label>
                                <v-select
                                    placeholder="Select Gender"
                                    :options="initialData.genderList"
                                    :reduce="option => option.value"
                                    label="name"
                                    v-model="formData.gender"
                                />
                            </div>
                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label" for="fullName">Baby name</label>
                                <input
                                    name="amount text-right"
                                    type="text"
                                    class="form-control"
                                    v-model.trim="formData.name"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">Weight(KG)</label>
                                <vField
                                    name="amount text-right"
                                    type="text"
                                    class="form-control"
                                    v-model.trim="formData.weight"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-6 col-lg-6 mb-1">
                                <label class="form-label">APGAR Score</label>
                                <vField
                                    name="amount text-right"
                                    type="text"
                                    class="form-control"
                                    v-model.trim="formData.apgar_score"
                                />
                            </div>

                            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1 p-2">
                                <AdmissionNote
                                    :note="initialData.note"
                                    @openModal="openModal"
                                />
                            </div>

                            <!-- <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1">
                                <label class="form-label">Upload Photo</label>
                                <div class="position-relative">
                                    <div class="profile-img-container d-flex justify-content-center align-items-center">
                                        <div class="position-relative profile-img show-shadow">
                                            <img :src="'http://localhost:8080/app-assets/images/profile-photo.png'" width="150" class="rounded img-fluid" alt="Card image"/>
                                            <div class="position-absolute top-0 end-0">
                                            <input type="file" class="d-none" ref="profile">
                                            <button type="button" title="Edit Your Profile Photo"
                                                    class="btn btn-icon btn-sm btn-icon rounded-0 btn-primary waves-effect waves-float waves-light">
                                                <i class="fas fa-pencil"></i>
                                            </button>
                                            </div>
                                        </div>
                                        <div class="profile-title ms-3">
                                            
                                        </div>
                                    </div>
                                </div>
                            </div> -->


                            <div class="col-12 text-center">
                                <hr>
                                <button @click="submit" type="submit" class="btn btn-primary me-1 mt-1 waves-effect waves-float waves-light">
                                    <!-- <div class="spinner-border spinner-border-sm text-light" role="status">
                                        <span class="visually-hidden">Loading...</span>
                                    </div> -->
                                    Submit
                                </button>
                                <button @click="closeModal" class="btn btn-outline-secondary mt-1 waves-effect">
                                    Cancel
                                </button>
                            </div>

                        </div>
                        
                    </div>

                </div>

                <AddNoteModal
                    v-if="store.state.isModalOpenTwo"
                    :note="singleNote"
                    :modalState="'isModalOpenTwo'"
                />

            </div>
        </div>

    </div>
</template>

<script setup>
import { inject, onMounted, reactive, ref } from "vue";
import { useStore } from "vuex";
import AdmissionNote from '@/components/molecule/company/hospital/note/Note.vue';
import AddNoteModal from '@/components/molecule/company/hospital/note/AddNoteModal.vue';
import { json_string_parse, json_string } from '@/services/utils/global';
import handleHospital from '@/services/modules/hospital'
import { useRoute } from "vue-router";
import { head } from 'lodash';

const props = defineProps({
    initialData: Object,
    editData: Object | null,
    admissionInfo: Object,
    onSubmit: Function
})

const emit = defineEmits(['onSubmit']);

const showError = inject('showError');
const showSuccess = inject('showSuccess');
const { storeBabyNote, updateBabyNote } = handleHospital()
const singleNote = ref(null);
const store = useStore();
const route = useRoute();
const loading = ref(false);
const formData = reactive({
    name: '',
    gender: null,
    weight: '',
    apgar_score: '',
    photo: ''
});

const closeModal = () => {
    store.state.isModalOpen = false;
}

const openModal = ({note, index}) => {
    singleNote.value = null;
    singleNote.value = {
        index: index,
        name: note.name,
        value: note.value,
        selectedNotes: note.selectedNotes,
        preAddedNotes: json_string_parse(note.preAddedNotes),
        noteMasters: note.noteMasters,
        newNotesByDoctor: note.newNotesByDoctor
    }
    
    store.state.isModalOpenTwo = true;
}

const submit = async () => {
    const formattedData = formatFormData();
    const id = props.editData ? props.editData.id : null;
    const otOrderAction = id ? updateBabyNote : storeBabyNote;

    loading.value = true

    await otOrderAction(formattedData, id).then((res) => {
        loading.value = false
        if (!res.status) return showError(res.message);
        showSuccess(res.message);
        closeModal();
        emit('onSubmit');
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const formatFormData = () => {
    const data = structuredClone(formData);
    data.notes = [];
    data.company_id = route.params.companyId;
    data.ipd_register_id = route.query.admissionId
    data.contact_profile_id = props.admissionInfo.contact_profile_id;

    const note = props.initialData.note;

    if(!note.selectedNotes.length) return data;

    data.notes.push({
        id: note.id ? note.id : null,
        type : note.value,
        note : json_string(note.selectedNotes),
        new_notes: note.newNotesByDoctor,
    });

    return data;
}

const setEditData = () => {
    formData.name = props.editData.name
    formData.gender = props.editData.gender
    formData.weight = props.editData.weight
    formData.apgar_score = props.editData.apgar_score
    formData.photo = props.editData.photo

    const firstNote = head(props.editData.notes)
    if(!firstNote) return;
    
    props.initialData.note.id = firstNote.id;
    props.initialData.note.selectedNotes = firstNote.note ?? [];
}

onMounted(() => {
    if(props.editData){
        setEditData();
    }
})

</script>

<style>
.discharge-rx .row {
    padding: 0 2% !important;
}
.discharge-rx {
    margin-top: 0 !important;
    margin-bottom: 2%;
    margin-left: 0 !important;
}

.med-title{
    padding: 0.486rem 1.5rem;
}
.modal-card{
    display: flex;
    flex-wrap: wrap;
    justify-content: space-evenly;
}
.add-note-modal {
    display: block; 
    background: #1c1d1c33
}
</style>