<template>
    <div class="card">

        <div class="bg-blue-light p-1">
            <TitleButton 
                :showBtn="false"
                :showAddNew="false"
                title="Discharge Order"
            />
        </div>

        <div class="my-2 px-2 mb-1">
            <TableHeaderData 
                :leftSideData="tableHeaderData.leftSideData"
                :rightSideData="tableHeaderData.rightSideData"
                rightColumnWidth="50%"
                v-model:dischargeDate="dischargeDate"
            />
        </div>

        <div class="row px-2">
            
            <div class="col-12 col-sm-6 col-md-6 col-lg-6 mb-1 note-section">
                <DischargeNote title="Notes on Admission">
                    <table>
                        <tr>
                            <td class="table-column">Admitted Department</td>
                            <td class="table-divider"></td>
                            <td class="table-row"> 
                                <strong>{{ admissionInfo.department ? admissionInfo.department.name : '' }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td class="table-column">Ward / bed / Cabib no</td>
                            <td class="table-divider"></td>
                            <td class="table-row"> 
                                <strong>{{ admissionInfo.service_resource.name }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td class="table-column">Admitted date & time</td>
                            <td class="table-divider"></td>
                            <td class="table-row"> 
                                <strong>{{ admissionInfo.formattedAdmissionData }}</strong>
                            </td>
                        </tr>
                        <tr>
                            <td class="table-column">Suffering from</td>
                            <td class="table-divider"></td>
                            <td class="table-row investigation-form"> 
                                <textarea v-model="formData.suffering_from" class="form-control" rows="2"></textarea>
                            </td>
                        </tr>
                    </table>
                </DischargeNote>
            </div>

            <div class="col-12 col-sm-3 col-md-3 col-lg-3 mb-1 note-section">
                <DischargeNote title="Admitting Consultant">
                    <p><strong>{{ admissionInfo.admitted_by_user.full_name }}</strong></p>
                </DischargeNote>
            </div>

            <div class="col-12 col-sm-3 col-md-3 col-lg-3 mb-1 note-section">
                <DischargeNote title="Treating Physicians">
                    <p><strong>{{ admissionInfo.human_resource.name }}</strong></p>
                </DischargeNote>
            </div>

            <div v-if="notesOrder.investigations.length" class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1 note-section">
                <DischargeNote title="Investigation Details" :isBackground="false">
                    <InvestigationTable 
                        :investigations="notesOrder.investigations"
                    />
                </DischargeNote>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1 note-section" v-if="notesOrder.otNotes.length">
                <h2>OT Notes</h2>
                <hr class="note-hr">
                <div class="row">
                    <DischargeOTNote 
                        v-for="note in notesOrder.otNotes" 
                        :key="note.id"
                        :note="note"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1 note-section" v-if="notesOrder.babyNotes.length">
                <h2>Baby Notes (Applicable for Maternity)</h2>
                <hr class="note-hr">
                <div class="row">
                    <DischargeBabyNote 
                        v-for="note in notesOrder.babyNotes" 
                        :key="note.id"
                        :note="note"
                    />
                </div>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1 note-section">
                <DischargeNote title="Discharge Medications">

                    <template #header>
                        <RxNote
                            class="discharge-rx"
                            :note="dischargeNotes.rx"
                            v-if="dischargeNotes.rx"
                            @openModal="openModal"
                            :medicineNotes="medicineNotes"
                        />
                    </template>

                    <table>
                        <tr>
                            <td class="table-column">Mode of Discharge</td>
                            <td class="table-divider"></td>
                            <td class="table-row mode-seg"> 
                                <div>
                                    <v-select 
                                        placeholder="Select Mode of Discharge" class="w-100" 
                                        :options="dischargeModes" label="name"
                                        v-model="formData.mode_of_discharge"
                                        :reduce="(name) => name.value" 
                                    />
                                </div>
                                
                            </td>
                        </tr>
                    </table>

                    <template #footer>
                        <AdmissionNote
                            class="discharge-rx"
                            :note="dischargeNotes.follow_up"
                            v-if="dischargeNotes.follow_up"
                            @openModal="openModal"
                        />
                        <RxNote
                            class="discharge-rx"
                            :note="dischargeNotes.investigation"
                            v-if="dischargeNotes.investigation"
                            @openModal="openModal"
                            :medicineNotes="medicineNotes"
                        />
                    </template>
                </DischargeNote>
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1 note-section">
                <AdmissionNote
                    class="discharge-rx"
                    :note="dischargeNotes.discharge_note"
                    v-if="dischargeNotes.discharge_note"
                    @openModal="openModal"
                />
            </div>

            <div class="col-12 col-sm-12 col-md-12 col-lg-12 mb-1 text-center">
                <button type="button" @click="submit" class="btn btn-primary me-1 waves-effect waves-float waves-light">
                    <div v-if="loading" class="spinner-border spinner-border-sm text-light" role="status">
                        <span class="visually-hidden">Loading...</span>
                    </div>
                    Save
                </button>
            </div>

        </div>

        <AddNoteModal
            v-if="store.state.isModalOpen"
            :note="singleNote"
        />

        <AddRxNoteModal
            v-if="store.state.isModalOpenTwo"
            :note="singleNote"
            :products="products"
        />

    </div>
</template>

<script setup>
import TitleButton from '@/components/atom/TitleButton';
import TableHeaderData from '@/components/molecule/company/hospital/ipd-station/components/DischargeHeader.vue';
import AdmissionNote from '@/components/molecule/company/hospital/note/Note.vue';
import RxNote from '@/components/molecule/company/hospital/note/RxNote.vue';
import AddNoteModal from '@/components/molecule/company/hospital/note/AddNoteModal.vue';
import AddRxNoteModal from '@/components/molecule/company/hospital/note/AddRxNoteModal.vue';
import handleHospital from '@/services/modules/hospital'
import DischargeNote from '@/components/molecule/company/hospital/ipd-station/DischargeNote.vue';
import DischargeBabyNote from '@/components/molecule/company/hospital/ipd-station/components/DischargeBabyNote.vue';
import DischargeOTNote from '@/components/molecule/company/hospital/ipd-station/components/DischargeOTNote.vue';
import InvestigationTable from '@/components/molecule/company/hospital/ipd-station/InvestigationTable.vue';
import handleCompany from "@/services/modules/company";
import dischargeOrderHelper from '@/services/utils/pdf/dischargeOrderHelper';

import { computed, inject, onMounted, reactive, ref } from 'vue';
import { head } from 'lodash';
import { useStore } from 'vuex';
import { useRoute, useRouter } from 'vue-router';
import { generateUUID, json_string_parse, formatDateToDdMmYy, json_string, getCurrentDateTime, groupBy, pluck } from '@/services/utils/global';

const props = defineProps({
    currentTab: String,
    products: Array,
    admissionInfo: Object,
})

const { fetchSingleDischargeOrder, storePrescription, updatePrescription, fetchDischargeStatus } = handleHospital();
const { fetchCompanyInfo } = handleCompany();
const { generatePdf, formatInvestigations  } = dischargeOrderHelper();

const singleNote = ref(null);
const selectedArray = ref('');
const store = useStore();
const route = useRoute();
const router = useRouter();
const loading = ref(false);
const loader = ref(false);
const showError = inject('showError');
const showSuccess = inject('showSuccess');
const medicineNotes = reactive([]);
const service = ref(null);
const companyInfo = ref({});

const dischargeNotes = reactive({
    discharge_note: null,
    rx: null,
    follow_up: null,
    investigation: null,
})
const notesOrder = reactive({
    babyNotes: [],
    otNotes: [],
    investigations: []
});
const newInvestigations = ref([]);

const tableHeaderData = reactive({
    leftSideData: [],
    rightSideData: [],
});
const dischargeDate = ref(getCurrentDateTime());
const formData = reactive({
    suffering_from: null,
    mode_of_discharge: null,
})
const dischargeModes = reactive([])

const gotoPrint = async() => {
    formData.dischargeDate = dischargeDate.value
    await generatePdf(companyInfo.value, props.admissionInfo, dischargeNotes, formData, notesOrder, dischargeModes);
}

const submit = async () => {
    const formData = formatFormData();
    const doctorOrderId = service.value.doctor_order && service.value.doctor_order.id;
    const prescriptionAction = doctorOrderId ? updatePrescription : storePrescription

    loading.value = true
    await prescriptionAction(formData, doctorOrderId).then((res) => {
        loading.value = false
        if (!res.status) return showError(res.message);
        onClickToClose();
        gotoPrint();
        return showSuccess(res.message);
    }).catch(err => {
        console.log(err)
    }).finally(() => {
        loading.value = false
    })
}

const onClickToClose = () => {
    router.push({name: 'ipd-station',
        params: route.params,
        query: route.query
    })
}

const formatFormData = () => {
    const {rx, investigation, other_notes} = formatNotes();
    const formattedData = {
        rx_notes: rx,
        investigations: investigation,
        other_notes: other_notes,
        contact_profile_id: service.value.contact_profile_id,
        service_general_id: service.value.id,
        service_resource_id: service.value.service_resource_id,
        company_id: route.params.companyId,
        prescription_type: props.currentTab,
        additional_info: json_string(formData),
        delivery_date_time: dischargeDate.value,
    }
    return formattedData;
}

const formatNotes = () => {
    const noteObj = {
        rx: [],
        investigation: [],
        other_notes: [],
    };

    const allNotes = Object.values(dischargeNotes).filter(item => item.selectedNotes.length);
    for(const note of allNotes){
        if(noteObj.hasOwnProperty(note.value)){
            noteObj[note.value].push(...note.selectedNotes);
            continue;
        }
        noteObj['other_notes'].push({
            id: note.id ? note.id : null,
            type : note.value,
            note : JSON.stringify(note.selectedNotes),
            new_notes: note.newNotesByDoctor
        });
    }
    return noteObj;
}

const openModal = ({note, index}) => {
    singleNote.value = null;
    singleNote.value = {
        index: index,
        name: note.name,
        value: note.value,
        selectedNotes: note.selectedNotes,
        preAddedNotes: json_string_parse(note.preAddedNotes),
        noteMasters: note.noteMasters,
        newNotesByDoctor: note.newNotesByDoctor
    }

    if(['rx', 'investigation'].includes(note.value)){
        store.state.isModalOpenTwo = true;
        return;
    }
    
    store.state.isModalOpen = true;
}

const getQuery = () => {
    let query = `?company_id=${route.params.companyId}`;
    query += `&admission_id=${route.query.admissionId}`;
    return query;
}

const setNoteData = (notes) => {
    for(let note of notes){
        dischargeNotes[note.value] = note;
    }
}

const setEditData = () => {

    notesOrder.babyNotes = service.value.ipd_register.baby_notes;
    notesOrder.otNotes = service.value.ipd_register.ot_orders;

    if(service.value.delivery_date_time){
        dischargeDate.value = getCurrentDateTime(service.value.delivery_date_time);
    }

    const investigationHistoryNote = head(service.value.ipd_register.notes)
    if(investigationHistoryNote){
        newInvestigations.value = newInvestigations.value.concat(investigationHistoryNote.note)
    }

    if(newInvestigations.value.length){
        setInvestigationData(newInvestigations.value)
    }

    const doctorOrder = service.value.doctor_order;
    if(!doctorOrder) return;

    if(doctorOrder.additional_info){
        setFormData(doctorOrder.additional_info);
    }

    if(doctorOrder.medicine_orders.length){
        setMedicineOrderData(doctorOrder.medicine_orders, 'rx');
    }
    
    if(doctorOrder.investigation_orders.length){
        setMedicineOrderData(doctorOrder.investigation_orders, 'investigation');
    }
    
    if(doctorOrder.notes.length){
        setOtherNoteData(doctorOrder.notes);
    }
}

const setInvestigationData = async (investigations) => {

    const investigationIds = pluck(investigations, 'id');
    const query = `?company_id=${route.params.companyId}&investigation_ids=${json_string(investigationIds)}`;

    await fetchDischargeStatus(query).then((res) => {
        if(!res.status) return;
        notesOrder.investigations = formatInvestigations(investigations, res.data);
    })

}

const setFormData = (additionalInfo) => {
    formData.mode_of_discharge = additionalInfo.mode_of_discharge;
    formData.suffering_from = additionalInfo.suffering_from
}

const setOtherNoteData = (other_notes) => {
    for(let noteItem of other_notes){

        let prevNote = dischargeNotes[noteItem.type]
        if(!prevNote) continue;

        prevNote.id = noteItem.id;
        prevNote.selectedNotes.push(...noteItem.note);
    }
}

const setMedicineOrderData = (medicineRx, noteType) => {
    
    for(let note of medicineRx){

        const noteObj = {
            id: note.id,
            product_id: note.product_id,
            name: note.product.name,
            remarks: note.remarks,
            isSelected: true,
        };

        if(noteType === 'rx'){
            noteObj['dose'] = note.dose;
            noteObj['unit'] = note.unit;
            noteObj['duration'] = note.duration;
            noteObj['madication_status'] = note.madication_status;
        }
        dischargeNotes[noteType].selectedNotes.push(noteObj)
    }
}

const fetchInitialData = async () => {
    const serviceId = route.params.serviceId;
    const query = getQuery();
    const prescriptionType = props.currentTab;

    loader.value = true;
    await Promise.all([
        fetchSingleDischargeOrder(query, serviceId, prescriptionType).then((res) => {
            if (!res.status) return showError(res.message)
            service.value = res.data.service;
            newInvestigations.value = res.data.investigations
            setNoteData(res.data.dischargeNotes)
            medicineNotes.push(...res.data.rxNotes)
            dischargeModes.push(...res.data.dischargeModes);
        }),
        fetchCompanyInfo(route.params.companyId).then((res) => {
            companyInfo.value = res.data
        })
    ]).then(() => {
        setEditData();
        loader.value = false;
    }).catch((err) => {
        loader.value = false
    });
}

const setInitialData = (admissionInfo) => {
    formatLeftSideData(admissionInfo.patient)
    setRightSideData(admissionInfo);
}

const formatLeftSideData = (patientInfo) => {
    tableHeaderData.leftSideData = [
        { key: 'Patient ID', value: patientInfo.serial_no },
        { key: 'Patient Name', value: patientInfo.full_name },
        { key: 'Sex & Age', value: patientInfo.gender + ' | ' + patientInfo.birthday },
        { key: 'Patient Phone No', value: patientInfo.mobile_no },
        { key: 'Patient Address', value: patientInfo.full_address },
    ];
}

const setRightSideData = (admissionInfo) => {
    tableHeaderData.rightSideData = [
        { key: 'Admission No', value: admissionInfo.admission_no },
        { key: 'Admission Date', value: formatDateToDdMmYy(admissionInfo.admission_date) },
    ]
}

onMounted(() => {
    setInitialData(props.admissionInfo)
    fetchInitialData();
})

</script>

<style scoped>
.note-hr{
    width: 100%;
}
.mode-seg{
    width: 65%;
}
.discharge-rx .row {
    padding: 0 2% !important;
}
.discharge-rx {
    width: 95%;
    margin: auto;
    margin-bottom: 2%;
}
.investigation-form{
    width: 64%;
}
.table-divider{
    padding: 3px 10px;
}
.table-divider:after {
    content: ":";
}
.tr-box-shadow{
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px !important;
}

.width-10{
    width: 10%;
}

address{
    margin-bottom: 0 !important;
}
</style>